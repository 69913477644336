import { useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import "./Info.scss";

const skills = [
  "JavaScript",
  "TypeScript",
  "React",
  "Redux",
  "NodeJs",
  "ExpressJs",
  "NextJs",
  "Golang",
  "React-Native",
  "MongoDB",
  "PostgrSQL",
  "HTML",
  "CSS & Sass",
  "Tailwind",
  "Storybook",
  "Jest",
  "HTTP & REST",
  "Git",
  "AdobeXd",
  "Ngrok",
  "Figma",
  "Asterisk IPBX",
];

const SkillsInfo = () => {
  const arrayLength = Math.round(skills.length / 3);

  const [firstArray, secondArray, thirdArray] = useMemo(
    () => [
      skills.slice(0, arrayLength),
      skills.slice(arrayLength, arrayLength * 2),
      skills.slice(arrayLength * 2, skills.length),
    ],
    [arrayLength]
  );

  return (
    <div className="SkillsInfo">
      <div className="SkillsInfo_column">
        {firstArray.map((skill) => (
          <div className="SkillsInfo_row">
            <FontAwesomeIcon className="SkillsInfo_icon" icon={faCaretRight} />
            <p>{skill}</p>
          </div>
        ))}
      </div>
      <div className="SkillsInfo_column">
        {secondArray.map((skill) => (
          <div className="SkillsInfo_row">
            <FontAwesomeIcon className="SkillsInfo_icon" icon={faCaretRight} />
            <p>{skill}</p>
          </div>
        ))}
      </div>
      <div className="SkillsInfo_column">
        {thirdArray.map((skill) => (
          <div className="SkillsInfo_row">
            <FontAwesomeIcon className="SkillsInfo_icon" icon={faCaretRight} />
            <p>{skill}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillsInfo;
